import { AccountTypeOptions } from 'pages/Users/enums';

export interface SelectOption {
  text: string;
  value: string | number;
}

export enum UserOrGroupSelectOptionType {
  User = 'user',
  Group = 'group',
}

export interface SelectUserOption extends SelectOption {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  company_name: string;
  account_type: AccountTypeOptions;
  type: UserOrGroupSelectOptionType.User;

  is_deleted?: boolean;
  unselectable?: boolean;
}

export interface SelectUserGroupOption extends SelectOption {
  id: number;
  num_of_members: number;
  type: UserOrGroupSelectOptionType.Group;
}

export type SelectUserAndGroupOption = {
  users: SelectUserOption[];
  groups: SelectUserGroupOption[];
};

export type SelectUserAndGroupOptionMap = {
  users: Map<number, SelectUserOption>;
  groups: Map<number, SelectUserGroupOption>;
};

/**
 * Specifies the selection limits for the {@link UsersAndGroupsExpandableSelect}.
 * How many users or groups can be picked at minimum or maximum.
 */
export type UsersAndGroupsSelectionCountLimits = {
  minUsers?: number;
  maxUsers?: number;
  minGroups?: number;
  maxGroups?: number;
};

/**
 * Specifies various constraints of the possible selections in {@link UsersAndGroupsExpandableSelect}
 */
export type UsersAndGroupsSelectLimits = {
  selectionCountLimits?: UsersAndGroupsSelectionCountLimits;
  isFieldRequired: boolean;
  isAllowedToSyncGroups: boolean;
  isAllowedToSelectGroupMembers: boolean;
};

export interface SelectOptionItem {
  value: string | number;
  key: string;
  label: string;
  searchKey?: string;
  isVisibleBulkSelection?: boolean;
  areVisibleFilters?: boolean;
  areVisiblePaginationControls?: boolean;
}

export interface OptionData extends SelectOption {
  title: string;
}

//this response contains more data, here is only what is needed
export interface AutocompleteResponse {
  results: SelectOption[];
  total_count: number;
}

//this response contains more data, here is only what is needed
export interface AutocompleteUsersResponse {
  results: SelectUserOption[];
  total_count: number;
}

export interface AutocompleteUserGroupsResponse {
  results: SelectUserGroupOption[];
  total_count: number;
}

export interface AutocompleteSelectValue {
  label: string;
  value: string;
}

export interface OptionAutocomplete {
  autocomplete: string;
}

export interface SingleSelectOption<T = string | number> {
  value: T;
  text: string;
}

export interface ItemsValidator {
  type: 'max_items';
  items: number;
}

export interface LengthValidator {
  type: 'min_length' | 'max_length';
  length: number;
}

export interface ValueValidator {
  type: 'min_value' | 'max_value';
  value: number;
}
export interface TypeValidator {
  type: 'allowed_extensions';
  extensions: string[];
}

export interface SizeValidator {
  type: 'size';
  size: number;
}

export interface FieldValueValidator {
  [key: string]: string | string[];
}

export type Validators = LengthValidator | FieldValueValidator;

export interface OptionSelect<T = string | number> {
  type?: string;
  required?: boolean;
  validators?: Array<Validators>;
  values?: SingleSelectOption<T>[];
  editable?: boolean;
  name?: {
    type?: string;
    required?: boolean;
    validators?: Array<Validators>;
    values?: SingleSelectOption<T>[];
  };
}
