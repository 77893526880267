import { prepareUserGroupOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserGroupOptions';
import { prepareUserOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserOptions';
import { uniqBy } from 'lodash';
import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';

/**
 * Builds a deduplicated list of SelectUserOption and SelectUserGroupOption from a list of provided options
 * and record metadata.
 */
export const buildOptionListFromOptionsAndRecordMetadata = (
  options: SelectUserAndGroupOption,
  objectRecordMetadata: ObjectRecordDetailsMeta | undefined
) => {
  const metaUsers = objectRecordMetadata?.users
    ? prepareUserOptions(Object.values(objectRecordMetadata?.users) ?? [])
    : [];

  const metaGroups = objectRecordMetadata?.user_groups
    ? prepareUserGroupOptions(
        Object.values(objectRecordMetadata?.user_groups) ?? []
      )
    : [];

  // get any data we have, either from meta or options.
  const users = uniqBy([...options.users, ...(metaUsers ?? [])], 'id');
  const groups = uniqBy([...options.groups, ...(metaGroups ?? [])], 'id');

  return { users, groups };
};
