import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { buildOptionListFromOptionsAndRecordMetadata } from './buildOptionListFromOptionsAndRecordMetadata';

/**
 * InPlaceEdit has only information about selected users and groups IDs. To be able to provide information about users and groups
 * in the picker, we need to filter out potential options from the metadata or fetched options.
 */
export const convertIdsToUserAndGroupOptions = (
  userIds: number[],
  groupIds: number[],
  options: SelectUserAndGroupOption,
  objectRecordMetadata: ObjectRecordDetailsMeta | undefined
) => {
  const {
    users: userOptionsToUse,
    groups: groupOptionsToUse,
  } = buildOptionListFromOptionsAndRecordMetadata(
    options,
    objectRecordMetadata
  );

  const users = userOptionsToUse.filter(user => userIds.includes(user.id));
  const groups = groupOptionsToUse.filter(group => groupIds.includes(group.id));

  return { users, groups };
};
