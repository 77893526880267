import { useCallback, useState } from 'react';
import axios from 'axios';
import { isNil } from 'lodash';
import { prepareUserGroupOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserGroupOptions';
import { prepareUserOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserOptions';
import { useCancelToken } from 'hooks/useCancelToken';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { fetchUserFieldUsersAndGroups } from './utils/fetchUserFieldUsersAndGroups';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';

/**
 * Handles async state of API data for user class field available options for selection.
 */
export const useObjectClassUserFieldOptions = (
  objectClassFieldId: number | null,
  objectClassId: string | number | undefined,
  onOptionsFetched?: (
    options: SelectUserAndGroupOption,
    isMinUsersFulfilled: boolean
  ) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<SelectUserAndGroupOption>();

  const { cancelOngoingRequests, createCancelToken } = useCancelToken();

  const fetchOptions = useCallback(async () => {
    if (isNil(objectClassFieldId) || isNil(objectClassId)) {
      return;
    }

    // Simple cache. If we have the options we do not refetch them. Full browser refresh is required to invalidate this cache.
    if (!isNil(options)) {
      return options;
    }

    try {
      setIsLoading(true);

      cancelOngoingRequests();
      const cancelToken = createCancelToken();

      const {
        users,
        groups,
        isMinUsersFulfilled,
      } = await fetchUserFieldUsersAndGroups(
        objectClassId,
        objectClassFieldId,
        cancelToken
      );

      const newOptions = {
        groups: prepareUserGroupOptions(Object.values(groups)),
        users: prepareUserOptions(Object.values(users)),
      };

      setOptions(newOptions);

      if (onOptionsFetched) {
        onOptionsFetched(newOptions, isMinUsersFulfilled);
      }

      return newOptions;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    cancelOngoingRequests,
    createCancelToken,
    objectClassFieldId,
    objectClassId,
    onOptionsFetched,
    options,
  ]);

  return {
    options: options ?? { users: [], groups: [] },
    isLoading,
    fetchOptions,
  };
};
