import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

/**
 * Returns appropriate fulfillment error message based on various conditions.
 *
 * @param isEditMode Is field in edit mode context.
 * @param minUsers Minimum number of users that must be selected, defined in the class field.
 *
 * @returns Fulfillment error message.
 */
export const getFulfillmentErrorMessage = (
  isEditMode: boolean,
  minUsers: number | undefined
) => {
  if (!isEditMode) {
    return GlobalIntlSingleton.intl.formatMessage({
      id: 'errors.notEnoughOptionsToSelectError',
      defaultMessage:
        'Not enough options to select. Please contact System Administrator.',
    });
  }

  if (minUsers) {
    return GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'errors.atLeastMinUsersMustBeSelected',
        defaultMessage:
          'At least {minUsers, plural, one {# user} other {# users}} must be selected. Not enough options to select. Please contact System Administrator.',
      },
      { minUsers }
    );
  }

  // If fulfillment validation failed with minUsers not defined, it means that the field has no
  // value and literally no options to select from.
  return GlobalIntlSingleton.intl.formatMessage({
    id: 'errors.atLeastOneOptionMustBeSelected',
    defaultMessage:
      'At least 1 option must be selected. Not enough options to select. Please contact System Administrator.',
  });
};
