import { StatusCodes } from 'http-status-codes';
import { apiCall } from 'utils/api';
import { COMPLETE_TASK, SAVE_TASK } from 'utils/endpoints';
import { Dispatch, SetStateAction, useState } from 'react';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { generatePath } from 'react-router-dom';
import { UseCompleteOrSaveTaskParams } from './types';
import { getFilesTokens } from 'components/FormPreview2/utils';
import axios from 'axios';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { RefetchResources } from 'contexts/types';
import { useIntl } from 'react-intl';
import { isDeletedUserNoLongerValidError } from './utils/isDeletedUserNoLongerValidError';
import { processDeletedUserNoLongerValidError } from './utils/processDeletedUserNoLongerValidError';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const useCompleteOrSaveTask = (
  taskId: number | string,
  setShouldNoPermissionBeDisplayed: Dispatch<SetStateAction<boolean>>,
  setIsTaskAlreadyCompleted: Dispatch<SetStateAction<boolean>>,
  setNoPermissionModalSubtitle: Dispatch<SetStateAction<string>>,
  setNoPermissionModalTitle: Dispatch<SetStateAction<string | undefined>>
) => {
  const [errors, setErrors] = useState<MappedObject<string[]> | undefined>();
  const intl = useIntl();
  const { refetchData } = useRefetchResourceContext();

  const completeOrSaveTask = async ({
    parsedFilesData,
    parsedFormData,
    isTaskSaving,
  }: UseCompleteOrSaveTaskParams) => {
    setErrors(undefined);

    try {
      await apiCall.post(
        generatePath(isTaskSaving ? SAVE_TASK : COMPLETE_TASK, { id: taskId }),
        {
          data: parsedFormData,
          files: getFilesTokens(parsedFilesData),
        }
      );

      refetchData(RefetchResources.Records);
      return true;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return false;
      }

      const data = error?.response?.data;

      if (data) {
        setErrors(data);
      }

      if (isTaskSaving) {
        setNoPermissionModalSubtitle(
          intl.formatMessage({
            id: 'misc.taskNoPermissionsSaveDescription',
            defaultMessage: 'You cannot save your response.',
          })
        );
      }

      switch (error?.response?.status) {
        case StatusCodes.FORBIDDEN: {
          setShouldNoPermissionBeDisplayed(true);

          return false;
        }
        case StatusCodes.NOT_FOUND: {
          setNoPermissionModalTitle(
            intl.formatMessage(
              {
                id: 'tasks.hasBeenDeleted',
                defaultMessage: 'Task {taskId} has been deleted',
              },
              { taskId }
            )
          );
          setNoPermissionModalSubtitle(
            intl.formatMessage({
              id: 'sequences.deletedSubtitle',
              defaultMessage: 'Your changes can no longer be saved.',
            })
          );
          setShouldNoPermissionBeDisplayed(true);

          return false;
        }
        case StatusCodes.BAD_REQUEST: {
          if (data?.detail === 'Completed already') {
            setShouldNoPermissionBeDisplayed(true);
            setIsTaskAlreadyCompleted(true);

            return false;
          }

          if (isDeletedUserNoLongerValidError(data?.data)) {
            const processedErrors = processDeletedUserNoLongerValidError(
              data?.data
            );

            setErrors(processedErrors);
            showErrorToast({
              title: intl.formatMessage({
                id: 'errors.invalidSelection',
                defaultMessage: 'Invalid selection',
              }),
              subtitle: intl.formatMessage({
                id: 'errors.someSelectedOptionsNoLongerAvailable',
                defaultMessage:
                  'Some selected options are no longer available. Please update the affected fields to continue.',
              }),
            });

            return false;
          }
        }
      }

      if (data) {
        showUnhandledErrorToast(error);
      }

      return false;
    }
  };

  return { completeOrSaveTask, errors };
};
