import React from 'react';
import { ExpandedUserGroupPanelProps } from './ExpandedUserGroupPanel.types';
import { useIntl } from 'react-intl';
import { useExpandedUserGroupPanelStyles } from './ExpandedUserGroupPanel.styles';
import { USER_GROUP_USERS } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import { GroupMember } from 'utils/types/api/userGroups.types';
import { Loader } from 'components/lib/Loader';
import { AccountTypeOptions } from 'pages/Users/enums';
import { Input } from 'components/lib/Input';
import { SearchBoldIcon } from 'components/Icon';
import { useInfiniteScroll } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/hooks';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { useUsersAndGroupsExpandablePickerStyles } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/components/UsersAndGroupsExpandablePicker/UsersAndGroupsExpandablePicker.styles';
import {
  USERS_AND_GROUPS_EXPANDED_SEARCH_FIELD_TESTID,
  USERS_AND_GROUPS_EXPANDED_USERS_LIST_TESTID,
  USERS_AND_GROUPS_SELECT_EXPANDED_DROPDOWN_CONTAINER_TESTID,
} from 'utils/testIds';
import EmptyData from 'components/EmptyData';
import NoMatchesFound from 'components/NoMatchesFound';
import { SyncGroupToggle } from './components/SyncGroupToggle';
import { UsersAndGroupsPickerRejectButton } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/components/UsersAndGroupsExpandablePicker/components/UsersAndGroupsPickerRejectButton';
import { ExpandedGroupMembersList } from './components/ExpandedGroupMembersList';
import { SelectGroupMemberOption } from './types/SelectGroupMemberOption';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';

/**
 * Represents the right side panel content in UserAndGroupsExpandablePicker when a group got clicked
 */
export const ExpandedUserGroupPanel = ({
  userGroup,
}: ExpandedUserGroupPanelProps) => {
  const intl = useIntl();
  const styles = useExpandedUserGroupPanelStyles();
  const sharedStyles = useUsersAndGroupsExpandablePickerStyles();

  const {
    selectedItems,
    editModeOptions,
    limits,
    onGroupMembersLoad,
  } = useUsersAndGroupsExpandablePickerContext();

  const { search, onSearchTextChange } = useDebouncedSearch();

  const apiPath = generatePath(
    `${USER_GROUP_USERS}?membership=member&ordering=first_name,last_name,username&search=${search.debouncedSearchText}`,
    {
      id: userGroup.id,
    }
  );

  const { results, initialLoadComplete, onScroll, loading } = useInfiniteScroll<
    SelectGroupMemberOption,
    GroupMember
  >({
    endpoint: apiPath,
    blockInitialLoad: false,
    // this search value is not used in this API call itself but it's needed to tell the useInfiniteScroll to refetch the data on search text change.
    searchText: search.debouncedSearchText,
    responseToDataConversionFunction: groupMember => ({
      ...groupMember,
      type: UserOrGroupSelectOptionType.User,
      account_type: AccountTypeOptions.Standard,
      company: groupMember.company_name,
      is_deleted: false,
      text: `${groupMember.first_name} ${groupMember.last_name} (${groupMember.company_name})`,
      value: groupMember.id,
    }),
    itemUniqueIdGenerationFunction: item => `${item.type}-${item.id}`,
    onSuccessfulFetch: onGroupMembersLoad,
  });

  const isSyncGroupMembers = !!selectedItems.groups.get(userGroup.id);

  return (
    <div
      className={styles.contentContainer}
      data-testid={USERS_AND_GROUPS_SELECT_EXPANDED_DROPDOWN_CONTAINER_TESTID}
    >
      <div className={styles.controlsContainer}>
        <Input
          prefix={
            loading && initialLoadComplete ? (
              <div className={styles.loadingIndicatorContainer}>
                <Loader size='small' />
              </div>
            ) : (
              <SearchBoldIcon size={12} className={styles.searchIcon} />
            )
          }
          onChange={onSearchTextChange}
          className={styles.searchInput}
          data-testid={USERS_AND_GROUPS_EXPANDED_SEARCH_FIELD_TESTID}
          placeholder={intl.formatMessage({
            id: 'placeholders.searchForUsers',
            defaultMessage: 'Search for users',
          })}
        />
        {limits.isAllowedToSyncGroups && (
          <SyncGroupToggle
            userGroup={userGroup}
            isSyncGroupMembers={isSyncGroupMembers}
          />
        )}
      </div>
      <div
        className={styles.userListContainer}
        onScroll={event => {
          event.stopPropagation();
          onScroll(event);
        }}
        data-testid={USERS_AND_GROUPS_EXPANDED_USERS_LIST_TESTID}
      >
        {!initialLoadComplete ? (
          <div className={sharedStyles.loadingContainer}>
            <Loader size='small' />
          </div>
        ) : (
          <>
            {results.length > 0 && (
              <ExpandedGroupMembersList
                items={results}
                textToHighlight={search.visibleSearchText}
                isSyncGroupMembers={isSyncGroupMembers}
              />
            )}
            {results.length === 0 && userGroup.num_of_members > 0 && (
              <NoMatchesFound />
            )}
            {results.length === 0 && userGroup.num_of_members === 0 && (
              <EmptyData
                title=''
                className={styles.emptyDataContainer}
                description={intl.formatMessage({
                  id: 'misc.noMembersOfGroup',
                  defaultMessage: 'There are no members of this group',
                })}
              />
            )}
          </>
        )}
      </div>
      {editModeOptions && (
        <UsersAndGroupsPickerRejectButton
          onClick={editModeOptions.onRejection}
          isSaving={editModeOptions.isSaving}
        />
      )}
    </div>
  );
};
