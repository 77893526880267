import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

/**
 * Processes the error data returned from the API when some deleted users beeing selected in user
 * type fields are no longer a valid choice. Related ticket #44680.
 *
 * @param data Error data returned from the API.
 *
 * @returns Processed error data with user type field errors only.
 */
export const processDeletedUserNoLongerValidError = (data: object) => {
  const fieldErrors = Object.entries(data);
  let processedErrors: Record<string, string[]> = {};

  for (const [fieldAlias, errorDetails] of fieldErrors) {
    if (typeof errorDetails !== 'object' || errorDetails === null) {
      continue;
    }

    if (!errorDetails.hasOwnProperty('users')) {
      continue;
    }

    const messages = (errorDetails as { users: string[] }).users;
    const extractedUserId = messages[0].match(/\d+/)?.[0];

    processedErrors = {
      ...processedErrors,
      [fieldAlias]: [
        GlobalIntlSingleton.intl.formatMessage(
          {
            id: 'errors.selectedDeletedUserIsNoLongerValid',
            defaultMessage:
              'User ID: {userId} is deleted and no longer valid. Please update your selection.',
          },
          { userId: extractedUserId }
        ),
      ],
    };
  }

  return processedErrors;
};
