import { stringifyJson } from 'utils/functions/stringifyJson';

/**
 * Checks if the error is caused by some deleted users beeing selected in user type fields that are
 * no longer a valid choice. Related ticket #44680.
 *
 * @param data Error data returned from the API.
 *
 * @returns True if the error is a "deleted user is no longer valid" error, false otherwise.
 */
export const isDeletedUserNoLongerValidError = (data: unknown) => {
  const regex = /"users":\["\\"\d+\\" is not a valid choice."\]/;
  const stringifiedData = stringifyJson(data, 0);

  return regex.test(stringifiedData ?? '');
};
