import { createUseStyles } from 'react-jss';

export const useInPlaceEditUserStyles = createUseStyles({
  userFieldWrapper: {
    marginBottom: 10,
  },
  editContentContainer: {
    width: 'auto',
    left: 0,
    right: '15px',
  },
});
