import { Modal } from 'components/lib/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorDetailsModal } from 'store/actions/miscActions';
import { getErrorDetailsModalData } from 'store/selectors/miscSelectors';
import { AppDispatch } from 'store/store';
import { useErrorDetailsModalStyles } from './ErrorDetailsModal.styles';
import { convertHexToRGBA } from 'utils/functions/convertHextoRGBA';
import { ReactComponent as ErrorIllustration } from 'img/illustrations/error.svg';
import { ButtonPrimary } from 'components/lib/Button';
import { CheckIcon } from 'components/Icon';
import { getApiVersion } from 'store/selectors/generalSelectors';
import { CopyButton } from 'components/CopyButton';
import { extractErrorDetails } from './utils/extractErrorDetails';
import moment from 'moment';
import { MODAL_CONFIRM_BUTTON_TESTID } from 'utils/testIds';

/**
 * Modal component to display error details. Allows to easily copy displayed data to clipboard.
 */
export const ErrorDetailsModal = () => {
  const styles = useErrorDetailsModalStyles();
  const dispatch = useDispatch<AppDispatch>();

  const { error, isVisible } = useSelector(getErrorDetailsModalData);
  const { apiVersion, historyVersion } = useSelector(getApiVersion);
  const { REACT_APP_VERSION } = process.env;

  const timestamp = moment().format();
  const errorDetails = extractErrorDetails(error);
  const textToCopy =
    `Title: ${errorDetails.title} \n` +
    `Method: ${errorDetails.method ?? '-'} \n` +
    `URL: ${errorDetails.url ?? '-'} \n` +
    `Message: ${errorDetails.message} \n` +
    `Timestamp: ${timestamp} \n` +
    `Location: ${window.location.href} \n` +
    `UI: ${REACT_APP_VERSION ?? '-'} \n` +
    `API: ${apiVersion ?? '-'} \n` +
    `History: ${historyVersion ?? '-'}`;

  const closeModal = () => {
    dispatch(closeErrorDetailsModal());
  };

  return (
    <Modal
      wrapClassName={styles.wrapper}
      visible={isVisible}
      footer={null}
      destroyOnClose
      closable={false}
      maskStyle={{
        zIndex: 10000,
        backgroundColor: convertHexToRGBA('#202134', 16),
      }}
    >
      <header className={styles.header}>
        <ErrorIllustration />

        <div className={styles.infoWrapper}>
          <h2 className={styles.title} title={errorDetails.title}>
            {errorDetails.title}
          </h2>

          <div>
            <p className={styles.location} title={window.location.href}>
              {window.location.href}
            </p>

            <p className={styles.versions}>
              <span>
                <FormattedMessage id='misc.ui' defaultMessage='UI' />
                <span>{`: ${REACT_APP_VERSION ?? '-'} | `}</span>
              </span>

              <span>
                <FormattedMessage id='misc.api' defaultMessage='API' />
                <span>{`: ${apiVersion ?? '-'} | `}</span>
              </span>

              <span>
                <FormattedMessage id='misc.history' defaultMessage='History' />
                <span>{`: ${historyVersion ?? '-'}`}</span>
              </span>
            </p>
          </div>
        </div>
      </header>

      <section className={styles.content}>
        {errorDetails.method && errorDetails.url && (
          <div>
            <h3 className={styles.paramLabel}>
              <FormattedMessage
                id='misc.methodAndUrl'
                defaultMessage='Method and URL'
              />
            </h3>
            <p className={styles.paramContent}>
              {errorDetails.method} {errorDetails.url}
            </p>
          </div>
        )}

        <div>
          <h3 className={styles.paramLabel}>
            <FormattedMessage id='misc.message' defaultMessage='Message' />
          </h3>
          <p className={styles.paramContent}>{errorDetails.message}</p>
        </div>

        <div>
          <h3 className={styles.paramLabel}>
            <FormattedMessage id='misc.timestamp' defaultMessage='Timestamp' />
          </h3>
          <p className={styles.paramContent}>{timestamp}</p>
        </div>
      </section>

      <footer className={styles.footer}>
        <CopyButton textToCopy={textToCopy} tooltipZIndex={10010} />

        <ButtonPrimary
          icon={<CheckIcon size={12} />}
          onClick={closeModal}
          data-testid={MODAL_CONFIRM_BUTTON_TESTID}
        >
          <FormattedMessage id='misc.ok' defaultMessage='Ok' />
        </ButtonPrimary>
      </footer>
    </Modal>
  );
};
