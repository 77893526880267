import { FormValue } from 'alx-dynamic-form';
import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useErrorMessages } from 'utils/useErrorMessages';
import { InPlaceEditError } from '../utils';

/**
 * Provides local validation for FormField value for the required constraint.
 *
 * @param isRequired Is required constraint.
 */
export const useFieldRequiredValidation = (isRequired: boolean | undefined) => {
  const errorMessages = useErrorMessages();

  const validateRequired = useCallback(
    (fieldValue: FormValue | null) => {
      if (isRequired && isNil(fieldValue)) {
        return [errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]];
      }

      return [];
    },
    [errorMessages, isRequired]
  );

  return { validateRequired };
};
