import { prepareUserGroupOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserGroupOptions';
import { prepareUserOptions } from 'components/UsersAndGroupsSelection/utils/prepareUserOptions';
import { ObjectRecordUserAndGroupMetadata } from 'utils/types/api/objectRecords.types';
import { SelectUserAndGroupOptionMap } from 'utils/types/selectInput.types';

/**
 * Creates selection map object based on provided metadata. Used to transform value metadata into
 * selection map.
 *
 * @param metadata Field value metadata.
 *
 * @returns Selection map object.
 */
export const createSelectionFromMetadata = (
  metadata: ObjectRecordUserAndGroupMetadata
): SelectUserAndGroupOptionMap => {
  const userOptions = prepareUserOptions(metadata.users);
  const groupOptions = prepareUserGroupOptions(metadata.groups);

  return {
    users: new Map(userOptions.map(user => [user.id, user])),
    groups: new Map(groupOptions.map(group => [group.id, group])),
  };
};
