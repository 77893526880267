import { InPlaceEditApiError } from './types';

export enum InPlaceEditError {
  FIELD_IS_REQUIRED_ERROR,
  FIELD_UNIQUE_ERROR,
  UNKNOWN_ERROR,
  INVALID_EMAIL_FORMAT,
  LIMIT_OF_CHARS_EXCEEDED,
  SELECTED_LESS_THAN_MINIMUM,
  SELECTED_MORE_THAN_MAXIMUM,
  INVALID_JSON_FORMAT,
}

export const parseFirstInPlaceEditApiError = (
  error: InPlaceEditApiError | undefined
) => {
  if (!error) return InPlaceEditError.UNKNOWN_ERROR;

  try {
    const [[firstErrorMessage]] = Object.values(error);

    switch (firstErrorMessage) {
      case 'This field must be unique.':
        return InPlaceEditError.FIELD_UNIQUE_ERROR;
      case 'This field may not be null.':
        return InPlaceEditError.FIELD_IS_REQUIRED_ERROR;
      case 'Ensure this field has no more than 5000 characters.':
        return InPlaceEditError.LIMIT_OF_CHARS_EXCEEDED;
      case 'Enter a valid email address.':
        return InPlaceEditError.INVALID_EMAIL_FORMAT;
      default:
        return InPlaceEditError.UNKNOWN_ERROR;
    }
  } catch {
    return InPlaceEditError.UNKNOWN_ERROR;
  }
};
