import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { Widget } from 'alx-dynamic-form';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from '../utils';
import { InPlaceEditUser } from 'components/InPlaceEditControls/InPlaceEditUser';
import { UsersAndGroupsFormValue } from './AdaptedInPlaceEditUser.types';

export const AdaptedInPlaceEditUser: Widget = widgetProps => {
  const {
    fieldId,
    objectClassId,
    value,
    propertyName,
    disabled,
    label,
    getPopupContainer,
    onSaveSuccess,
    previewClassName,
    readOnly,
    remountChangeTarget,
    required,
    showRawValue,
    size,
    withUnderline,
    patchUrl,
    recordId,
  } = mapCustomWidgetPropsToBaseInPlaceEditProps<UsersAndGroupsFormValue>(
    widgetProps
  );

  const {
    minUsers,
    maxUsers,
    minGroups,
    maxGroups,
    allowGroupSync,
    allowGroupMemberSelection,
  } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditUser
        fieldId={fieldId}
        objectClassId={objectClassId}
        recordId={recordId}
        disabled={disabled}
        label={label}
        minGroups={minGroups}
        maxGroups={maxGroups}
        patchUrl={patchUrl}
        getPopupContainer={getPopupContainer}
        onSaveSuccess={onSaveSuccess}
        previewClassName={previewClassName}
        readOnly={readOnly}
        remountChangeTarget={remountChangeTarget}
        required={required}
        showRawValue={showRawValue}
        size={size}
        withUnderline={withUnderline}
        value={value}
        propertyName={propertyName}
        minUsers={minUsers}
        maxUsers={maxUsers}
        allowGroupSync={allowGroupSync}
        allowGroupMemberSelection={allowGroupMemberSelection}
        meta={widgetProps.additionalFieldProps?.meta}
      />
    </Provider>
  );
};
