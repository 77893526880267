import React, { useState } from 'react';
import Tooltip from 'components/lib/Tooltip';
import { CopyButtonProps } from './CopyButton.types';
import { ButtonOutlined } from 'components/lib/Button';
import { CopyIcon } from 'components/Icon';
import { useCopyButtonStyles } from './CopyButton.styles';
import { useIntl } from 'react-intl';
import { COPY_BTN_TESTID } from 'utils/testIds';

/**
 * Icon button allowing to copy text to clipboard.
 */
export const CopyButton = ({ textToCopy, tooltipZIndex }: CopyButtonProps) => {
  const intl = useIntl();
  const styles = useCopyButtonStyles();

  const [isCopied, setIsCopied] = useState(false);

  const copyMessage = intl.formatMessage({
    id: 'misc.copy',
    defaultMessage: 'Copy',
  });
  const copiedMessage = intl.formatMessage({
    id: 'misc.copied',
    defaultMessage: 'Copied!',
  });

  const handleMouseEnter = () => {
    if (!isCopied) {
      return;
    }

    setIsCopied(false);
  };

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);

    setIsCopied(true);
  };

  return (
    <Tooltip
      shouldBeRendered
      overlay={isCopied ? copiedMessage : copyMessage}
      mouseEnterDelay={0}
      zIndex={tooltipZIndex}
    >
      <ButtonOutlined
        className={styles.button}
        onClick={copyTextToClipboard}
        onMouseEnter={handleMouseEnter}
        data-testid={COPY_BTN_TESTID}
      >
        <CopyIcon className={styles.icon} minWidth={0} />
      </ButtonOutlined>
    </Tooltip>
  );
};
